// -------------------------- //
// ----- LOADING SCREEN ----- //
// -------------------------- //

$(window).on('load', function() {
    $('#loading').delay(100).fadeOut(550);
    tabManager();
});

// -------------------- //
// ------ LOADER ------ //
// -------------------- //

window.addLoader = function(e) {
    $(e).addClass('disabled pe-none').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="vertical-align:-4px;"></span>');
}

// ----------------------------------------------------- //
// ----- ADD PADDING-TOP TO BODY WITH FIXED NAVBAR ----- //
// ----------------------------------------------------- //

function paddingTopBody() {

    let fixedHeight = 0;

    // Check if #nav-main.fixed-top exists and get its height
    const navbar = document.querySelector('#nav-main.fixed-top');

    if (navbar && window.innerWidth <= 991.98) {
        const navbarHeight = navbar.offsetHeight;
        fixedHeight += navbarHeight;
    }

    document.body.style.paddingTop = `${fixedHeight}px`;
}

// ON LOAD
document.addEventListener('DOMContentLoaded', function() {
    paddingTopBody();
});

// ON RESIZE
let cachedWidth = window.innerWidth;

window.addEventListener('resize', function() {
    const newWidth = window.innerWidth;
    if (newWidth !== cachedWidth) {
        // Update cached width and call paddingTopBody on resize
        cachedWidth = newWidth;
        paddingTopBody();
    }
});

// ------------------------------------- //
// ----- FORMAT AND CALCULATE TIME ----- //
// ------------------------------------- //

window.timeFormat = function(e, duration = false) {
    var val = $(e).val();

    if (val) {

        if (! duration) {
            //If length is 3, add zero and insert colon before last 2 characters
            if (val.indexOf(':') == -1 && val.length == 3) {
                val = '0' + val;
                val = val.match(/\d{2}/g).join(':');
            }
        }

        //If length is 4, insert colon before last 2 characters
        if (val.indexOf(':') == -1 && val.length == 4) {
            val = val.match(/\d{2}/g).join(':');
        }

        //If no colon is found, add :00
        if (val.indexOf(':') == -1) {
            val += ':00';
        }

        //If multiple colons are found, replace all but first colon
        if ((val.match(/:/g) || []).length > 1) {
            val = val.replace(/(?<=:.*):/g, '');
        }

        //Split value into array
        var val_array = val.split(':');

        if (! duration) {
            //If first part of array exceeds 23 or has more than 2 characters, set 23
            if (val_array[0] > 23 || val_array[0].length > 2) {
                val_array[0] = '23';
                val_array[1] = '59';
            }
        } else {
            //If first part of array exceeds 499 or has more than 3 characters, set 499
            if (val_array[0] > 499 || val_array[0].length > 3) {
                val_array[0] = '499';
                val_array[1] = '59';
            }
        }

        //If first part of array is empty, set 0
        if (val_array[0].length == 0) {
            val_array[0] = '0';
        }

        //If second part of array is empty, set 0
        if (val_array[1].length == 0) {
            val_array[1] = '0';
        }

        //If second part of array exceeds 59 or has more than 2 characters, set 59
        if (val_array[1] > 59 || val_array[1].length > 2) {
            val_array[1] = '59';
        }

        //Join the two parts together
        val = val_array.join(':');

        //Add zero to singular integers before and after colon
        val = val.replace(/^\d{1}:/, '0$&').replace(/:\d{1}$/, '$&0');

        $(e).val(val.toString());
    }
}

// todo: rewrite timeCalc function
window.timeCalc = function(prefix = '') {
    // Controleren of er zowel 'from' als 'to' tijden beschikbaar zijn
    if ($('#' + prefix + 'from').val() && $('#' + prefix + 'to').val()) {
        // Haal de waarden van de 'from' en 'to' tijdvelden op
        var from = $('#' + prefix + 'from').val();
        var to = $('#' + prefix + 'to').val();

        // Splits de tijd waarden in uren en minuten
        from = from.split(':');
        to = to.split(':');

        // Verwerk en converteer de tijdwaarden naar numerieke waarden
        var hoursFrom = parseInt(from[0], 10);
        var minsFrom = parseInt(from[1], 10);
        var hoursTo = parseInt(to[0], 10);
        var minsTo = parseInt(to[1], 10);

        // Als er een pauze aanwezig is, verwerkt deze en past uren/minuten daarop aan
        if ($('#' + prefix + 'pause').val()) {
            var pause = $('#' + prefix + 'pause').val();
            pause = pause.split(':');
            var hoursPause = parseInt(pause[0], 10);
            var minsPause = parseInt(pause[1], 10);
            hoursTo = hoursTo - hoursPause;
            minsTo = minsTo - minsPause;

            var hours = hoursTo - hoursFrom, mins = 0;

            // Controleer of uren/minuten correct blijven na pauze, corrigeer indien nodig
            if (minsTo < minsFrom) {
                mins = (minsTo + 60) - minsFrom;
                hours--;
                if(mins < 0) {
                    hours--;
                }
            }

            // Als de berekening ongeldige negatieve uren oplevert, reset het veld en probeer opnieuw
            if (hours < 0) {
                $('#' + prefix + 'pause').val('');
                return timeCalc(prefix);
            }
        }

        var hours = hoursTo - hoursFrom, mins = 0;

        // Bereken het totale verschil in minuten, controleer correctheid
        if (minsTo < minsFrom) {
            mins = (minsTo + 60) - minsFrom;
            hours--;
            if(mins < 0) {
                mins = mins + 60;
                hours--;
            }
        } else {
            mins = minsTo - minsFrom;
        }

        // Als de berekening ongeldige negatieve uren oplevert, reset het veld en probeer opnieuw
        if (hours < 0) {
            $('#' + prefix + 'to').val('');
            return timeCalc(prefix);
        }

        // Formatteer de minuten en uren met een voorafgaand '0' indien nodig
        if (mins <= 9) {
            mins = '0' + mins;
        }
        if (hours <= 9) {
            hours = '0' + hours;
        }

        // Vul de berekende duur in het 'duration'-veld in
        $('#' + prefix + 'duration').val(hours + ':' + mins);
    } else {
        // Als 'from' of 'to' leeg is, leeg het 'duration'-veld
        $('#' + prefix + 'duration').val('');
    }
}

window.scheduleCalc = function(prefix = '') {
    if ($('#' + prefix + 'user').val() && $('#' + prefix + 'start').val() && $('#' + prefix + 'end').val()) {
        var user = $('#' + prefix + 'user').val(), start = $('#' + prefix + 'start').val(), end = $('#' + prefix + 'end').val(), from = $('#' + prefix + 'from').val(), to = $('#' + prefix + 'to').val();

        addLoader('#' + prefix + 'basic-addon1');
        $('button[type="submit"]').addClass('disabled pe-none');
        $.ajax({
            url: '/schedule',
            type: 'post',
            data: {
                _token: CSRF_TOKEN,
                user: user,
                start: start,
                end: end,
                from: from,
                to: to,
            },
            success: function(data) {
                $('#' + prefix + 'duration').val(data);
                $('#' + prefix + 'basic-addon1').html('uren');
                $('button[type="submit"]').removeClass('disabled pe-none');
            }
        });
    } else {
        $('#' + prefix + 'duration').val('');
    }
}

// ------------------------ //
// ---- DATE FUNCTIONS ---- //
// ------------------------ //

$(function () {
    var d = new Date();
    d.setDate(d.getDate()).toLocaleString();

    var t = $('.week').find('[data-date="' + d.toISOString().split('T')[0] + '"]');

    if(REGISTRATIONS_PERIOD && REGISTRATIONS_PERIOD.indexOf('custom_') >= 0) {
        d = new Date(REGISTRATIONS_PERIOD.split('_')[1]);
        d.setDate(d.getDate()).toLocaleString();

        t = $('.week').find('[data-date="' + d.toISOString().split('T')[0] + '"]');
    }

    if(t.length == 0) {
        t = $('.week .nav-link').first();
        if(t.length == 0) {
            t = $('#date');
            changeDay(t);
        } else {
            t.tab('show');
            changeDay(t);
        }
    } else {
        var e = new Date();
        e.setDate(e.getDate()).toLocaleString();

        var u = $('.week').find('[data-date="' + e.toISOString().split('T')[0] + '"]')

        u.addClass('today');
        t.tab('show');
        changeDay(t);
    }
});

window.changeDay = function(t) {
    var d = new Date($(t).data('date'));
    d.setDate(d.getDate()).toLocaleString();

    // var d_start = new Date(d.getFullYear(), 0, 1);
    // d_start.setDate(d_start.getDate() + 1).toLocaleString();

    // var week = Math.ceil(Math.floor((d - d_start) / (24 * 60 * 60 * 1000)) / 7);

    var days = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    var months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    $('#date').val($(t).data('date'));
    $('#date').attr('data-date', $(t).data('date'));
    $('#datepicker').datepicker('update', $(t).data('date'));
    $('#date-label').html(d.getDate() + ' ' + months[d.getMonth()]);
    $('#date-title').html(days[d.getDay()] + ' ' + d.getDate() + ' ' + months[d.getMonth()]);

    $('.fader').next().each(function() {
        updateFaders(this);
    });
}

window.thisDay = function(e) {
    var d = new Date();
    d.setDate(d.getDate()).toLocaleString();

    var t = $('.week').find('[data-date="' + d.toISOString().split('T')[0] + '"]');

    if(t.length == 0) {
        addLoader(e);
        $.ajax({
            url: '/period',
            type: 'post',
            data: {
                _token: CSRF_TOKEN,
                period: 'custom_' + d.toISOString().split('T')[0],
                view: location.pathname.split('/')[1],
            },
            success: function(data) {
                location.reload();
            }
        });
    } else {
        t.tab('show');
        changeDay(t);
    }
}

window.addDay = function(e) {
    var t = $('.week .nav-link.active');
    var d = new Date($(t).data('date'));
    d.setDate(d.getDate() + 1).toLocaleString();

    t = $('.week').find('[data-date="' + d.toISOString().split('T')[0] + '"]');

    if(t.length == 0) {
        addLoader(e);
        $.ajax({
            url: '/period',
            type: 'post',
            data: {
                _token: CSRF_TOKEN,
                period: 'custom_' + d.toISOString().split('T')[0],
                view: location.pathname.split('/')[1],
            },
            success: function(data) {
                location.reload();
            }
        });
    } else {
        t.tab('show');
        changeDay(t);
    }
}

window.subDay = function(e) {
    var t = $('.week .nav-link.active');
    var d = new Date($(t).data('date'));
    d.setDate(d.getDate() - 1).toLocaleString();

    t = $('.week').find('[data-date="' + d.toISOString().split('T')[0] + '"]');

    if(t.length == 0) {
        addLoader(e);
        $.ajax({
            url: '/period',
            type: 'post',
            data: {
                _token: CSRF_TOKEN,
                period: 'custom_' + d.toISOString().split('T')[0],
                view: location.pathname.split('/')[1],
            },
            success: function(data) {
                location.reload();
            }
        });
    } else {
        t.tab('show');
        changeDay(t);
    }
}

// --------------------------- //
// ---- COPY TO CLIPBOARD ---- //
// --------------------------- //

window.copyToClipboard = function(element, text) {
    var temp = $('<input>');
    $('body').append(temp);
    temp.val(text.substring(1, text.length - 1)).select();
    document.execCommand('copy');
    temp.remove();
    $(element).attr('data-bs-original-title', 'Gekopieerd!').tooltip('show');
    setTimeout(function() {
        $(element).attr('data-bs-original-title', text.substring(1, text.length - 1)).tooltip('hide');
    }, 2500);
}

// ------------------------- //
// ----- SMOOTH SCROLL ----- //
// ------------------------- //

$(function() {
    var menuHeight = $('#nav-main').outerHeight();
    $('a[href*="#"][class*="scroll"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
            || location.hostname == this.hostname) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - menuHeight
                }, 1000);
                return false;
            }
        }
    });
});

// -------------------- //
// ----- TOOLTIPS ----- //
// -------------------- //

$(function () {
    $('[data-bs-toggle="tooltip"]').tooltip()
});

// -------------------- //
// ------ ALERTS ------ //
// -------------------- //

$(document).ready(function(){
    // $('.alert').fadeTo(2000, 500).slideUp(500, function(){
    //     $('.alert').slideUp(500);
    // });
    setTimeout(function() {
        $('.alert:not(.alert-permanent)').fadeOut('slow');
    }, 2000);
});

// -------------------- //
// ------ NAVBAR ------ //
// -------------------- //

$(function() {
    $(document).click(function (event) {
        if (!$(event.target).is('.navbar-collapse *')) {
            $('.navbar-collapse').collapse('hide');
        }
    });
});

// ------------------ //
// ----- FADERS ----- //
// ------------------ //

$(function() {
    $('.fader').next().scroll(function() {
        updateFaders(this);
    });
});

window.updateFaders = function(e) {
    var scrollLeft = $(e).scrollLeft();
    var divWidth = $(e).outerWidth().toFixed();
    var scrollWidth = $(e).get(0).scrollWidth;

    if (scrollWidth - divWidth === 0) {
        //Not scrollable
        $(e).parent().find('.fader:first-child').fadeOut(250);
        $(e).parent().find('.fader:last-child').fadeOut(250);
    } else if (scrollLeft === 0) {
        //Scrolled to the left
        $(e).parent().find('.fader:first-child').fadeOut(250);
        $(e).parent().find('.fader:last-child').fadeIn(250);
    } else if (scrollLeft < scrollWidth - divWidth) {
        //Scrolled in the middle
        $(e).parent().find('.fader:first-child').fadeIn(250);
        $(e).parent().find('.fader:last-child').fadeIn(250);
    } else {
        //Scrolled to the right
        $(e).parent().find('.fader:first-child').fadeIn(250);
        $(e).parent().find('.fader:last-child').fadeOut(250);
    }
}

// ----------------------- //
// ----- TAB MANAGER ----- //
// ----------------------- //

$('.tm-nav-link').on('click', function() {
    setActiveTab(this);
});

window.tabManager = function() {
    const tabs = JSON.parse(sessionStorage.getItem('tm_active_tabs'));
    let current_tabs = [];

    if(tabs) {
        current_tabs = tabs.filter(obj => obj.location === window.location.pathname);
    }

    if(current_tabs.length !== 0) {
        let set_default_first_tab = true;
        let set_default_first_first_tab = true;
        let set_default_first_second_tab = true;
        $(current_tabs).each(function( index ) {
            if (current_tabs[index].index === 0) {
                set_default_first_tab = false;
            }

            if (current_tabs[index].index === 1) {
                set_default_first_first_tab = false;
            }

            if (current_tabs[index].index === 2) {
                set_default_first_second_tab = false;
            }

            if(window.location.pathname === current_tabs[index].location) {
                showTab(current_tabs[index].id);
            }
        });

        if(set_default_first_tab === true) {
            showTab('first-tab');
        }

        if(set_default_first_first_tab === true) {
            showTab('first-first-tab');
        }

        if(set_default_first_second_tab === true) {
            showTab('second-first-tab');
        }
    } else {
        showTab('first-tab');
        showTab('first-first-tab');
        showTab('second-first-tab');
    }
}

window.setActiveTab = function(e){
    const id = $(e).attr('id');
    let index = 0;

    if($(e).attr('data-tab-manager-index')) {
        index = parseInt($(e).attr('data-tab-manager-index'));
    }

    let tabs = [];
    if(sessionStorage.getItem('tm_active_tabs') !== null) {
        tabs = JSON.parse(sessionStorage.getItem('tm_active_tabs'));
    }

    const current_tabs = tabs.find(item => item.location === window.location.pathname);
    if(current_tabs) {
        const existing_tab = tabs.find(item => item.index === index && item.location === window.location.pathname);
        if(existing_tab) {
            existing_tab.id = id;
            existing_tab.location = window.location.pathname;
        } else {
            tabs.push({'index' : index, 'id' : id, 'location' : window.location.pathname});
        }
    } else {
        tabs.push({'index' : index, 'id' : id, 'location' : window.location.pathname});
    }

    sessionStorage.setItem('tm_active_tabs', JSON.stringify(tabs));
}

window.showTab = function(id) {
    //todo: replace showing tabs with tab('show') function
    $('#' + id).addClass('active');
    $('#' + id).attr('aria-selected', 'true');
    $('#' + id + '-pane').addClass('show');
    $('#' + id + '-pane').addClass('active');
}

window.userAndGroupSelect = function(e) {
    if(e.params.args.data.users) {
        e.preventDefault();
        $.each(e.params.args.data.users, function (index, user) {
            // If select already has unselected option, add it to current selected and select all
            if($(e.currentTarget).find('option:not(:selected)').filter('[value="' + user.id + '"]').length) {
                var selected = $(e.currentTarget).find('option:selected').map(function() {
                    return parseInt($(this).val());
                }).get();
                selected.push(user.id);
                $(e.currentTarget).val(selected).trigger('change');
                // If select does not have unselected option or selected option, add new option and select it
            } else if(!$(e.currentTarget).find('option:selected').filter('[value="' + user.id + '"]').length) {
                $(e.currentTarget).append(new Option(user.name + ' ' + user.surname, user.id, true, true)).trigger('change');
            }
        });

        $('.select2-search__field').val('');
        $('.select2-container--open .select2-search__field').focus();
    }
}


// --------------------- //
// ----- BIRTHDAYS ----- //
// --------------------- //

window.celebrate = function() {
    var duration = 2.5 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function() {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);

        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    }, 250);
}

// --------------------- //
// ----- REPORTING ----- //
// --------------------- //

window.getReportData = function(type, active) {
    if (type.split('_')[0] === 'all') {
        if (type.split('_')[1] === 'user') {
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsNonBillable');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsBillable');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsBilled');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsLeaves');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsDiseases');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsPaidPause');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsTotal');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsSchedule');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsScheduleProgress');
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodRevenue');
        } else if (type.split('_')[1] === 'team') {
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersNonBillable', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersBillable', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersBilled', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersLeaves', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersDiseases', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersPaidPause', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersTotal', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersSchedule', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodSecondsUsersScheduleProgress', active);
            getReportData('single_' + type.split('_')[1] + '_' + type.split('_')[2] + '_' + type.split('_')[3] + '_periodRevenueUsers', active);
        }
    } else if (type.split('_')[0] === 'single') {
        addLoader($('#' + type));
        $.ajax({
            url: '/report',
            type: 'post',
            data: {
                _token: CSRF_TOKEN,
                model: type.split('_')[1],
                id: type.split('_')[2],
                segment: type.split('_')[3],
                type: type.split('_')[4],
                active: active,
            },
            success: function (data) {
                $('#' + type).html(data);
            }
        });
    }
}
